import React, { useEffect } from 'react'

//Imported Images
import logoWhite from "../../img/logo.svg";
import logoBlack from "../../img/logo-black.svg";
import sectionOneImg from "../../img/section-one.png";
import sectionTwoImg from "../../img/section-two.png";
import sectionthreeImg from "../../img/section-three.png";
import sectionfourImg from "../../img/section-four.png";
import sectionFiveImg from "../../img/section-five.png";
import sectionsixImg from "../../img/section-six.png";
import sectionSevenImg from "../../img/section-seven.png";
import sectioneightImg from "../../img/section-eight.png";
import contactImg from "../../img/contact-us.png";
import call from "../../img/call.png";
import mail from "../../img/mail.png";
import tick from "../../img/tick.png";
import badge from "../../img/badge.png";


//LAYOUT
import { Helmet } from "react-helmet";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

//HUBSPOT
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";

const Marketpage = () => {
    const { loaded, error, formCreated } = useHubspotForm({
        region: "na1",
        portalId: "19573488",
        formId: "93f20bd7-1619-49cb-bd37-ba5f95844106",
        target: "#my-hubspot-form2",
    });
    
    useEffect(() => {
        AOS.init({
            once: true
        });
    }, []);

    useEffect(() => {
        if(AOS){
            AOS.refresh();
        }
    });

    return (
        <div>
            <Helmet>
                <title>
                    OIC Website Builder | Build A Perfect Website With OIC Website Builder
                </title>
                <meta httpEquiv="content-language" content="en-IN" />
                <meta
                    name="title"
                    content="OIC Website Builder | Build A Perfect Website With OIC Website Builder"
                />
                <meta
                    name="description"
                    content="Create a mobile-friendly site with OIC website builder. Each template is a ready-made design for the optical industry with inbuilt content that can be customized."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.oicapps.com/" />
                <meta
                    property="og:description"
                    content="Create a mobile-friendly site with OIC website builder. Each template is a ready-made design for the optical industry with inbuilt content that can be customized."
                />

            </Helmet>
            {/* Header */}
            <div className="staticPagebanner myContainer">
                <div className="topnav ">
                    <div className="row">
                        <div className="col-6">
                        <img src={logoBlack} width="80" className="logo"></img>
                        </div>
                    </div>
                </div>
                <div className="container fluid col-xl-12 banner-content">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-start flex-column">
                            <h1 className="banner-txt">
                                build a perfect website with <br />
                                <span> OIC website builder</span>{" "}
                            </h1>
                            {/* <form className="signup-market" id="my-hubspot-form2">
                                <input id="name" name="name" type="text" placeholder="Name" />
                                <input id="contact" name="contact" type="number" placeholder="Contact Number" />
                                <input id="email" name="email" type="mail" placeholder="Email" />
                                <textarea id="comment" name="comment" type="text" placeholder="Message" />
                                <button
                                    type="button"
                                    // onClick={() =>
                                    // window.open("https://website-builder.oicapps.com/", "_blank")
                                    // }
                                    className="btn-1 "
                                >
                                    Sign up now
                                </button>
                            </form> */}
                            <div id="my-hubspot-form2"></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* section one */}
            <div className="backgroundGrey myContainer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xl-6">
                            <img src={sectionOneImg} className="img-responsive " />
                        </div>
                        <div className="col-md-12 col-xl-6 contentContainer">
                            <h2>
                                Your website <br /> one-stop-shop
                            </h2>
                            <p>
                                Includes everything you'll need to create a successful website.
                                From in-built inventories to website hosting—we do it all.
                            </p>
                            <button
                                type="button"
                                onClick={() =>
                                window.open("https://website-builder.oicapps.com/", "_blank")
                                }
                                className="btn-1"
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* section two */}
            <div className="backgroundWhite myContainer section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xl-6 contentContainer"
                            data-aos="fade-right" data-aos-anchor-placement="top-center" >
                            <h2>Build</h2>
                            <p>
                                Create awesome responsive websites. Easy & fast - No coding!
                                Templates specially designed for optical shops.
                            </p>
                            <button
                                type="button"
                                onClick={() =>
                                window.open("https://website-builder.oicapps.com/", "_blank")
                                }
                                className="btn-1"
                            >
                                Learn More
                            </button>
                        </div>

                        <div className="col-md-12 col-xl-6" data-aos="fade-left"
                            data-aos-delay="200" data-aos-anchor-placement="top-center" >
                            <img src={sectionTwoImg} className="img-responsive " />
                        </div>
                    </div>
                </div>
            </div>

            {/* section three */}
            <div className="backgroundWhite myContainer bg-dummy ">
                <div className="container-fluid">
                    <div className="row section-two">
                        <div className="col-md-12 col-xl-6" data-aos="fade-right"
                            data-aos-delay="200" data-aos-anchor-placement="top-center" >
                            
                            <img src={sectionthreeImg} className="img-responsive " />
                        
                        </div>

                        <div className="col-md-12 col-xl-6 contentContainer"
                            data-aos="fade-left" data-aos-anchor-placement="top-center" >
                            <h2>Themes</h2>
                            <p>
                                Choose from a wide range of attractive themes for your website.
                                Have the option to customise your theme to your liking.
                            </p>
                        <button type="button"
                            onClick={() =>
                            window.open("https://website-builder.oicapps.com/", "_blank")
                            } className="btn-1">
                            Get started
                        </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* middle banner */}
            <div className="middleBanner myContainer ">
                <div className="container">
                <div className="row">
                    <div className="col-12 text-center contentContainer align-items-center">
                    <h2>In-built Content</h2>
                    <p className="text-center">
                        It's arguably the most critical weapon for achieving the firm's
                        marketing objectives. Pre-built content for the themes. The
                        modifying content in the templates can be tailored to your
                        liking.
                    </p>
                    </div>
                </div>
                </div>
            </div>

            {/* section four */}
            <div className="backgroundWhite myContainer section">
                <div className="container-fluid">
                    <div className="row section-two">
                        <div className="col-md-12 col-xl-6" data-aos="fade-right"
                            data-aos-anchor-placement="top-center" >
                            <img src={sectionfourImg} className="img-responsive " />
                        </div>
                        
                        <div className="col-md-12 col-xl-6 contentContainer" data-aos="fade-left" 
                            data-aos-delay="200" data-aos-anchor-placement="top-center" >
                            <h2>Publish</h2>
                            <p>
                                Go live on a fast, reliable, and hassle-free that scales with
                                your business.
                            </p>
                            <button
                                type="button"
                                onClick={() =>
                                window.open("https://website-builder.oicapps.com/", "_blank")
                                }
                                className="btn-1"
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* section five */}
            <div className="backgroundWhite myContainer bg-dummy bg-dummy-2 ">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-12 col-xl-6 contentContainer" data-aos="fade-right"
                            data-aos-delay="200" data-aos-anchor-placement="top-center" >
                            <h2>Add your own Products</h2>
                            <p>
                                You may also make your own inventory available to consumers by
                                adding it to the website. To draw clients, update inventory
                                stocks on the website and keep them up to date on the latest
                                items.
                            </p>
                            <button
                                type="button"
                                onClick={() =>
                                window.open("https://website-builder.oicapps.com/", "_blank")
                                }
                                className="btn-1" >
                                Get Started
                            </button>
                        </div>

                        <div className="col-md-12 col-xl-6" data-aos="fade-left"
                            data-aos-anchor-placement="top-center" >
                            <img src={sectionFiveImg} className="img-responsive " />
                        </div>
                    </div>
                </div>
            </div>

            {/* middle banner */}
            <div className="middleBanner myContainer mid-two">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center contentContainer align-items-center">
                            <h2>Product Catalog</h2>
                            <p className="text-center">
                                Allows flexibility in displaying products of various SKUs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* section six */}
            <div className="backgroundWhite myContainer section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xl-6 contentContainer"
                            data-aos="fade-right" data-aos-anchor-placement="top-center">
                            <h2>Grow</h2>
                            <p>
                                Create, launch, and iterate on new marketing campaigns and gain
                                new customers for your store.
                            </p>
                            <button
                                type="button"
                                onClick={() =>
                                window.open("https://website-builder.oicapps.com/", "_blank")
                                }
                                className="btn-1"
                            >
                                Learn More
                            </button>
                        </div>
                        <div className="col-md-12 col-xl-6" data-aos="fade-left"
                            data-aos-delay="200" data-aos-anchor-placement="top-center">
                            <img src={sectionsixImg} className="img-responsive " />
                        </div>
                    </div>
                </div>
            </div>
            
            {/* section seven */}
            <div className="backgroundWhite myContainer bg-dummy bg-dummy-2 ">
                <div className="container-fluid">
                    <div className="row section-two">
                        <div className="col-md-12 col-xl-6" data-aos="fade-right"
                            data-aos-delay="200" data-aos-anchor-placement="top-center">
                            <img src={sectionSevenImg} className="img-responsive " />
                        </div>
                        
                        <div className="col-md-12 col-xl-6 contentContainer"
                            data-aos="fade-left" data-aos-anchor-placement="top-center">
                            <h2>Share URL</h2>
                            <p>
                                Customers can access the products available by sharing the
                                website URL, which allows retailers to share it with them.
                            </p>
                            <button
                                type="button"
                                onClick={() =>
                                window.open("https://website-builder.oicapps.com/", "_blank")
                                }
                                className="btn-1">
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* middle banner */}
            <div className="middleBanner myContainer mid-three">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center contentContainer align-items-center">
                            <h2>Interested Products - Try On Glasses From Home</h2>
                            <p className="text-center">
                                The client can pick the involved products from the inventories
                                and take it to the subsequent stage of buy. Until ordering,
                                research your product on the website and schedule an appointment
                                to make the transaction more convenient at your doorstep.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* section eight */}
            <div className="backgroundWhite myContainer section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xl-6 contentContainer"
                            data-aos="fade-right" data-aos-anchor-placement="top-center">
                            <h2>Home/ Store Visit</h2>
                            <p>
                                The purchaser's interest in a product can be effectively
                                translated into a transaction by welcoming them to the store or
                                scheduling an appointment for them at their home.
                            </p>
                            <button
                                type="button"
                                onClick={() =>
                                window.open("https://website-builder.oicapps.com/", "_blank")
                                }
                                className="btn-1"
                            >
                                Learn More
                            </button>
                        </div>
                        <div className="col-md-12 col-xl-6" data-aos="fade-left"
                            data-aos-anchor-placement="top-center">
                            <img src={sectioneightImg} className="img-responsive " />
                        </div>
                    </div>
                </div>
            </div>

            {/* pricing start */}
            <div className="myContainer pricing">
                <div className="container-fluid">
                    <div className="row pricingCont">
                        <div className="col-xl-12 contentContainer text-center">
                            <h2>Pricing</h2>
                            <p>Find The Perfect Plan For You!</p>
                        </div>
                        <div className="row justify-content-between pricingCont">
                            <div className="col-xl-3 pricingCard">
                                <h4>Basic</h4>
                                <div>
                                    <p className="dollar">$8 </p>
                                    <p className="dollarType">
                                        USD <br />
                                        /MO
                                    </p>
                                </div>
                                <ul>
                                    <li>
                                        <img src={tick} width="10" height="10" />
                                        <p>static one pager</p>
                                    </li>
                                    <li>
                                        <img src={tick} width="10" height="10" />
                                        <p>Mobile Responsive Website</p>
                                    </li>
                                    <li>
                                        <img src={tick} width="10" height="10" />
                                        <p>Hosting The Website On Our Cloud</p>
                                    </li>
                                </ul>
                                <button type="button"
                                    onClick={() =>
                                        window.open(
                                        "https://website-builder.oicapps.com/",
                                        "_blank"
                                        )
                                    }
                                    className="btn-1 btn-border"
                                >
                                Choose Basic
                                </button>
                            </div>
                            <div className="col-xl-3 pricingCard">
                                <img src={badge} className="badgeImage" />
                                <h4>essential</h4>
                                <div>
                                    <p className="dollar">$15 </p>
                                    <p className="dollarType">
                                        USD <br />
                                        /MO
                                    </p>
                                </div>
                                <ul>
                                    <li style={{ color: "#ff9810" }}>
                                        <img src={tick} width="10" height="10" />
                                        <p>features in basics +</p>
                                    </li>

                                    <li>
                                        <img src={tick} width="10" height="10" />
                                        <p>website + integrated with inventory management</p>
                                    </li>
                                    <li>
                                        <img src={tick} width="10" height="10" />
                                        <p>15000+ Branded inventory library</p>
                                    </li>
                                    <li>
                                        <img src={tick} width="10" height="10" />
                                        <p>Lead Generation</p>
                                    </li>
                                </ul>
                                <button type="button"
                                    onClick={() =>
                                        window.open(
                                        "https://website-builder.oicapps.com/",
                                        "_blank"
                                        )
                                    } className="btn-1 "
                                >
                                Choose Essential
                                </button>
                            </div>
                            <div className="col-xl-3 pricingCard">
                                <h4>pro</h4>
                                <div>
                                    <p className="dollar">$25 </p>
                                    <p className="dollarType">
                                        USD <br />
                                        /MO
                                    </p>
                                </div>
                                    <ul>
                                    <li style={{ color: "#ff9810" }}>
                                        <img src={tick} width="10" height="10" />
                                        <p> Features In essential +</p>
                                    </li>
                                    <li>
                                        <img src={tick} width="10" height="10" />
                                        <p> seo Enabled</p>
                                    </li>
                                    <li>
                                        <img src={tick} width="10" height="10" />
                                        <p> Site Analytics</p>
                                    </li>
                                </ul>
                                <button type="button"
                                    onClick={() =>
                                        window.open(
                                        "https://website-builder.oicapps.com/",
                                        "_blank"
                                        )
                                    }
                                    className="btn-1 btn-border"
                                >
                                Contact Pro
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* pricing end */}
            
            {/* contact us */}
            <div className="myContainer contact-us">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <img src={contactImg} className="img-responsive " />
                        </div>
                        <div className="col-md-12 col-xl-6 contact-content">
                            <h2>its time to change your life today</h2>
                            <button
                                type="button"
                                onClick={() =>
                                window.open("https://website-builder.oicapps.com/", "_blank")
                                }
                                className="btn-1 btn-white"
                            >
                                Contact us
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* end */}

            <footer className="myContainer">
                <div>
                    <div className="container-fluid ">
                        <div className="row">
                            <div className="col-md-4 ">
                                <img src={logoWhite} height="40" alt="OiC App"></img>

                                <p>
                                OiC is an online marketplace platform primarily built for the
                                optical sector to ease the challenge of distributors/retailers
                                under a single roof to digitally increase their efficiency
                                </p>
                                <button
                                type="button"
                                onClick={() =>
                                    window.open(
                                    "https://website-builder.oicapps.com/",
                                    "_blank"
                                    )
                                }
                                className="btn-1 btn-white"
                                >
                                Contact us
                                </button>
                            </div>

                            <div className="col-md-2">
                                <ul className="footeras">
                                <li>
                                    <a href="https://oicapps.com/about-us">ABOUT US</a>
                                </li>
                                <li>
                                    <a href="https://oicapps.com/#products">PRODUCTS</a>
                                </li>
                                <li>
                                    <a href="https://oicapps.com/#blog">BLOGS</a>
                                </li>
                                <li>
                                    <a href="https://oicapps.com/privacy-policy">
                                    PRIVACY POLICY
                                    </a>
                                </li>
                                </ul>
                            </div>
                        
                            <div className="col-md-3">
                                <ul className="footeras">
                                <li>
                                    <a href="https://oicapps.com/terms-conditions">
                                    TERMS & CONDITIONS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://oicapps.com/faqs">FAQ</a>
                                </li>
                                <li>
                                    <a href="https://oicapps.com/sitemap">SITE MAP</a>
                                </li>
                                <li>
                                    <a href="https://oicapps.com/press-kit">MEDIA</a>
                                </li>
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <p>
                                Polaris House,<br></br> No. 244, Anna Salai,<br></br> Chennai,
                                Tamil Nadu 600006 <br></br>
                                </p>
                                <p>
                                <br></br>
                                <br></br>
                                <b>Registered Address : </b>
                                <br></br>
                                ADHYAS SOFTWARE PRIVATE LIMITED<br></br>
                                128, DEFENCE COLONY, <br></br>
                                EKKATUTHANGAL, Chennai,<br></br>
                                Tamil Nadu, 600032 <br></br> <br></br>
                                </p>
                                <p>
                                <img src={call} height="15" alt="OiC App"></img> +91 97918
                                36596
                                </p>
                                <p>
                                <img src={mail} height="15" alt="OiC App"></img> {""}
                                <a href="mailto:marketing@oicapps.com">
                                    {" "}
                                    marketing@oicapps.com
                                </a>
                                </p>
                            </div>
                        </div>
                        
                        <hr />
                        
                        <div className="row text-center">
                            <ul className="socialLinks">
                                <li>
                                    <a
                                        href="https://www.facebook.com/OIC-105456311612773"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <i className="fa fa-facebook-official"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/oicapps/"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.linkedin.com/company/oicapps/"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <i className="fa fa-linkedin-square"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://twitter.com/OIC02395398"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <i className="fa fa-twitter-square"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Marketpage;
